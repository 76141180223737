<template>
  <div class="button-wrapper" :class="theme">
    <b-button class="btn-login w-100" variant="primary" v-b-modal.modal-register v-if="!memberSid"><i class="fas fa-user-plus"/> {{$t("login.club-member-register")}}</b-button>

    <b-modal id="modal-register" v-model="modalShow" :dir="(lang !== 'he') ? 'ltr': 'rtl'" >
      <template #modal-header="{ close }">
        <h5>{{ $t('login.member-register') }}</h5>
        <b-button size="sm" @click="close()" variant="second">
          <i class="fa fa-times"></i>
        </b-button>
      </template>

      <template #default>
        <b-alert :variant="registerSuccess? 'success': 'danger'" show v-if="!!message">{{ message }}</b-alert>

        <b-form-group id="input-first-name" :label="`${$t('login.first-name')}:`" label-for="input-first-name" :class="{'he': lang === 'he'}">
          <b-form-input
            id="input-first-name"
            v-model="firstName"
            :placeholder="$t('login.first-name')"
            required
            type="text"
            v-bind:class="{'is-invalid' : !validText(firstName)}"
          ></b-form-input>
          <div class="invalid-feedback">{{$t("login.validate-first-name-message")}}</div>
        </b-form-group>

        <b-form-group id="input-last-name" :label="`${$t('login.last-name')}:`" label-for="input-last-name" :class="{'he': lang === 'he'}">
          <b-form-input
            id="input-last-name"
            v-model="lastName"
            :placeholder="$t('login.last-name')"
            required
            type="text"
            v-bind:class="{'is-invalid' : !validText(lastName)}"
          ></b-form-input>
          <div class="invalid-feedback">{{$t("login.validate-last-name-message")}}</div>
        </b-form-group>

        <b-form-group id="input-email" :label="`${$t('login.email')}:`" label-for="input-email" :class="{'he': lang === 'he'}">
          <b-form-input
            id="input-email"
            v-model="email"
            :placeholder="$t('login.email')"
            required
            dir="ltr"
            type="email"
            v-bind:class="{'is-invalid' : !validEmail(email)}"
          ></b-form-input>
          <div class="invalid-feedback">{{$t("login.validate-email-message")}}</div>
        </b-form-group>

        <b-form-group id="input-group-phone" :label="`${$t('login.phone-number')}:`" label-for="input-phone" :class="{'he': lang === 'he'}">
          <b-form-input
            id="input-phone"
            v-model="phone"
            :placeholder="$t('login.phone-number')"
            required
            dir="ltr"
            type="number"
            v-bind:class="{'is-invalid' : !validPhone(phone)}"
          ></b-form-input>
          <div class="invalid-feedback">{{$t("login.validate-phone-message")}}</div>
        </b-form-group>

        <b-form-group id="check-group-news-letter"  label-for="check-news-letter" :class="{'he': lang === 'he'}">
          <b-form-checkbox
            id="check-news-letter"
            v-model="newsletter"
            name="newsLetter"
            checked
          >
            {{$t('login.news-letter')}}
          </b-form-checkbox>
        </b-form-group>

      </template>

      <template #modal-footer="{ close }">
        <b-button size="sm" variant="success" @click="send" :disabled="isSending">
          <span v-if="isSending"><b-spinner small type="grow"></b-spinner>{{$t('login.sending')}}</span>
          <span v-else>{{$t('login.register')}}</span>
        </b-button>
        <b-button size="sm" variant="danger" @click="close()" :disabled="isSending">
         {{$t('login.cancel')}}
        </b-button>
      </template>

    </b-modal>
    <b-toast
      id="countDown"
      toaster="b-toaster-top-right"
      :variant="registerSuccess? 'success': 'warning'"
      :autoHideDelay="maxSec * 1000"
      solid
      v-model="showCountDown"
    >
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline">
          <h5>{{ message }}</h5>
        </div>
      </template>
      {{ message }}
    </b-toast>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BFormGroup, BFormInput, BSpinner, BButton, BAlert, BToast, BModal, VBModal } from 'bootstrap-vue';

export default {
  name: 'login',
  props: {
    theme: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      newsletter: true,
      modalShow: false,
      showCountDown: false,
      maxSec: 5,
      registerSuccess: false,
      valid: false,
    };
  },
  components: {
    BFormGroup, BFormInput, BSpinner, BButton, BAlert, BToast, BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  computed: {
    ...mapGetters({
      isLogin: 'IS_AUTHORIZED',
      memberSid: 'GET_MEMBER_SID',
      user: 'GET_MEMBER',
      isSending: 'GET_IS_AUTHORING',
      lang: 'GET_LANGUAGE',
      message: 'GET_ERROR_MESSAGE',
    }),
  },
  watch: {
    modalShow() {
      this.firstName = '';
      this.lastName = '';
      this.email = '';
      this.phone = '';
      this.newsletter = true;
      this.$store.commit('SET_ERROR_MESSAGE', null);
    },
    message() {
      if (this.message) this.showCountDown = true;
    },
  },
  methods: {
    validate() {
      if (this.validEmail(this.email) && this.validText(this.firstName) && this.validText(this.lastName) && this.validPhone(this.phone)) {
        this.valid = true;
      }
    },
    validText(str) {
      return !(!str || /^\s*$/.test(str));
    },
    validPhone(value) {
      return /^-?\d+$/.test(value);
    },
    validEmail(email) {
      const re = /(.+)@(.+){2,}\.(.+){2,}/;
      return re.test(email.toLowerCase());
    },
    async send() {
      const { firstName, lastName, email, newsletter, phone } = this;

      this.validate();
      if (this.valid) {
        const body = { firstName, lastName, email, newsletter, phone };
        this.registerSuccess = await this.$store.dispatch('REQUEST_CLUB_MEMBER_REGISTER', body);
        if (this.registerSuccess) this.$store.commit('SET_ERROR_MESSAGE', this.$t('register-success'));
      }
    },
  },
};
</script>

<style scoped>
.theme1 button.btn-login{
  color: #1993C8;
  border-color: #ffffff;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  padding: 8px 20px;
}
/* .btn-login {
  min-width: 200px;
  font-size: 1.5rem;
}
.button-wrapper{
  margin-right: 1rem;
}
@media (max-width: 479px) {
  .button-wrapper{
    margin-top: 1rem;
    margin-right: 0;
    text-align: center;
  }
} */
</style>
<style>
/* #modal-register{
  text-align: unset;
} */
#modal-register .he .d-block, #modal-register .he .alert, #modal-register .he .custom-checkbox{
  text-align: right;
}
#modal-register .he .custom-checkbox .custom-control-label::before{
  left: auto;
  right: -1.5rem;
}
#modal-register .he .custom-checkbox .custom-control-label::after{
  left: auto;
  right: -1.5rem;
}
#modal-register .he .custom-checkbox .custom-control-label{
  margin-right: 1.5rem;
}
#modal-register .modal-dialog {
  width: 800px;
}
@media(max-width: 600px){
  #modal-register .modal-dialog {
    width: 90%;
  }
}
</style>
